<form id="electricty-form" class="__electricity-form" [formGroup]="electricityForm">
    <div class="u-flex-row u-flex-justify-content-space-between u-margin-bottom">
        <div class="u-flex-row u-flex-justify-content-flex-start">
            <smvd-ui-svg-illustration
                [svg]="svgIllustration.MetersElectricity"
                class="u-icon-24 u-margin-right-half"
                [class.u-color-gray-dark]="activeControl.value"
                [class.u-color-gray-light]="!activeControl.value"
            ></smvd-ui-svg-illustration>
            <h5 [class.u-color-gray-light]="!activeControl.value">{{ 'ELECTRICITY' | translate }}</h5>
        </div>
        <mat-slide-toggle
            *ngIf="electricityForm.enabled"
            [formControlName]="fields.Active"
            color="primary"
            disableRipple="true"
        ></mat-slide-toggle>
    </div>

    <ng-container *ngIf="activeControl.value; else inactive">
        <app-select-input
            id="meter-type-field"
            [custom]="false"
            width="auto"
            [formControlName]="fields.MeterType"
            [direction]="uiDirection.Vertical"
            [options]="meterTypeOptions"
            class="__meter-type-field"
        ></app-select-input>

        <app-ean-code-input
            width="100%"
            [formControlName]="fields.EanCode"
            [placeholder]="placeholders.eanPlaceholder | translate"
            [label]="'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.LABEL' | translate"
        ></app-ean-code-input>

        <div class="u-margin-bottom-double">
            @if (electricityMeterNumberInputVisible()) {
                <smvd-ui-text-input
                    width="100%"
                    [formControlName]="fields.MeterNumberElectricity"
                    [label]="'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.LABEL' | translate"
                    [placeholder]="'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.PLACEHOLDER' | translate"
                ></smvd-ui-text-input>
                <p class="text-text-secondary text-body-md">
                    {{ 'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.RECOMMENDATION' | translate }}
                </p>
            } @else {
                <a class="text-body-md text-text-secondary cursor-pointer"
                   (click)="showElectricityMeterNumberInput($event)"
                >{{ 'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.ADD_METER_NUMBER.LABEL' | translate }}
                    <span class="text-text-interactive underline cursor-pointer hover:text-text-interactive-hover">{{
                            'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.ADD_METER_NUMBER.CTA' | translate
                        }}</span>
                </a>
            }
        </div>

        <div [ngSwitch]="meterTypeControl.value" class="u-margin-bottom">
            <!-- single -->
            <ng-container *ngSwitchCase="meterType.Single">
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.SingleMeterReading"
                    [label]="'METER_READING_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
            </ng-container>

            <!-- double -->
            <ng-container *ngSwitchCase="meterType.Double">
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.DoubleDayMeterReading"
                    [label]="'METER_READING_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.DoubleNightMeterReading"
                    [label]="'METER_READING_NIGHT' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
            </ng-container>

            <!-- digital -->
            <ng-container *ngSwitchCase="meterType.Digital">
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.ConsumptionDayMeterReading"
                    [label]="'ENERGY.METER_READING.DIGITAL.CONSUMPTION_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.ConsumptionNightMeterReading"
                    [label]="'ENERGY.METER_READING.DIGITAL.CONSUMPTION_NIGHT' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>

                <app-check-input
                    [custom]="false"
                    width="auto"
                    [formControlName]="fields.HasInjection"
                    [option]="hasInjectionOption"
                ></app-check-input>

                <ng-container *ngIf="hasInjectionControl.value">
                    <smvd-ui-meter-reading-input
                        width="100%"
                        [formControlName]="fields.InjectionDayMeterReading"
                        [label]="'ENERGY.METER_READING.DIGITAL.INJECTION_DAY' | translate"
                        [placeholder]="placeholders.injectionkWhLabel | translate"
                    ></smvd-ui-meter-reading-input>
                    <smvd-ui-meter-reading-input
                        width="100%"
                        [formControlName]="fields.InjectionNightMeterReading"
                        [label]="'ENERGY.METER_READING.DIGITAL.INJECTION_NIGHT' | translate"
                        [placeholder]="placeholders.injectionkWhLabel | translate"
                    ></smvd-ui-meter-reading-input>
                </ng-container>
                @if (showAutoReadout) {
                    <app-check-input
                        [custom]="false"
                        width="auto"
                        [formControlName]="fields.AutomaticDigitalReadings"
                        [option]="automaticDigitalReadingOption"
                    ></app-check-input>
                }
            </ng-container>
        </div>

        <app-check-input
            [custom]="false"
            width="auto"
            [formControlName]="fields.ExclusiveNight"
            [option]="exclusiveNightOption"
        ></app-check-input>
        <app-icon
            appTooltip
            icon="info"
            [template]="exclusiveNightTemplate"
            [alignment]="alignment.Right"
            class="u-margin-left-xs"
            [hidePointer]="true"
        ></app-icon>

        <smvd-ui-meter-reading-input
            *ngIf="exclusiveNightControl.value"
            width="100%"
            [formControlName]="fields.ExclusiveNightMeterReading"
            [label]="'METER_READING_EXCLUSIVE_NIGHT' | translate"
            [placeholder]="placeholders.consumptionkWhLabel | translate"
        ></smvd-ui-meter-reading-input>
    </ng-container>
</form>

<ng-template #inactive
><p class="u-color-gray-dark" id="inactive-text">{{ 'ENERGY.METER_READING.INACTIVE.ELECTRICITY' | translate }}</p>
</ng-template>

<ng-template #exclusiveNightTemplate>
    <p>{{ 'ENERGY.METER_READING.EXCLUSIVE_NIGHT.TOOLTIP' | translate }}</p>
</ng-template>
